<template>
  <div class="card-list-area">
    <template v-if="listItems.length">
      <div class="card-list-area__header">
        <p class="card-list-area__w-70">Организация</p>
        <p>Сортировка</p>
      </div>
      <div class="card-list-area__body">
        <div v-for="(item, index) in listItems" :key="index" class="card-list-area__body-item">
          <div class="card-list-area__w-70">
            <SelectSingleComponentCheckbox
              v-model.number="item.organizationId"
              placeholder="Выберите организацию"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              :options="options"
              :canClear="false"
            />
          </div>
          <div class="card-list-area__w-20">
            <ComponentInput placeholder="Введите" v-model.number="item.orderNum" mod="rectangular" type="number" />
          </div>
          <IconComponent @click="deleteItem(index)" name="delete-not-fill" class="card-delete-icon" />
        </div>
      </div>
    </template>

    <div v-if="getDuplicates" class="mb-10">
      <span class="invalid-msg">Пожалуйста, удалите пустые или дублирующиеся элементы</span>
    </div>

    <div><span :class="['card-add-btn', { disabled: getDuplicates }]" @click="addItem">Добавить организацию</span></div>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import IconComponent from '@/common/ui/IconComponent';

  export default {
    name: 'CardListItems',
    components: { SelectSingleComponentCheckbox, ComponentInput, IconComponent },
    props: {
      listItems: {
        type: Array,
        default: () => [],
      },
      options: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      getDuplicates() {
        const idsOrganization = this.listItems.map((i) => i.organizationId);
        const duplicates = idsOrganization.filter((organization, index) => {
          return idsOrganization.indexOf(organization) !== index;
        });
        return duplicates.length;
      },
    },
    watch: {
      getDuplicates(val) {
        this.$emit('duplicates', val);
      },
    },
    methods: {
      addItem() {
        this.$emit('add');
      },
      deleteItem(index) {
        this.$emit('delete', index);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../styles/StepCard.scss';
</style>
