<template>
  <div :class="[headerClass, 'card-list-area']">
    <template v-if="actions.length">
      <div class="card-list-area__header">
        <p class="card-list-area__w-8" :class="{ 'mobile-dis-none': isWithLink }">№ п/п</p>
        <p class="card-list-area__w-60" :class="{ 'mobile-dis-none': isWithLink }">{{ columnName }}</p>
        <p v-if="isWithLink" class="card-list-area__w-20 link-header">Ссылка</p>
      </div>
      <div class="card-list-area__body">
        <div v-for="(item, index) in actions" :key="index" class="card-list-area__body-item">
          <div class="card-list-area__w-8" :class="{ 'mobile-dis-none': isWithLink }">
            {{ item?.orderNum ?? index + 1 }}
          </div>
          <div class="card-list-area__w-60">
            <ComponentInput
              v-if="isWithLink"
              v-model="item.name"
              mod="rectangular"
              :maxLength="nameMaxLength"
              placeholder="Введите"
            ></ComponentInput>
            <ComponentInput
              v-else
              type="textarea"
              placeholder="Введите"
              v-model="item.action"
              mod="rectangular"
              :maxLength="nameMaxLength"
              :textareaStyles="{ height: '80px' }"
              noResize
            ></ComponentInput>
          </div>
          <div v-if="isWithLink" class="card-list-area__w-20">
            <ComponentInput placeholder="Введите" v-model="item.url" mod="rectangular"></ComponentInput>
          </div>
          <IconComponent @click="deleteAction(index)" name="delete-not-fill" class="card-delete-icon" />
        </div>
      </div>
    </template>

    <div>
      <span @click="addAction" class="card-add-btn" :class="{ 'card-add-btn--disabled': isDisableBtn }">{{
        btnName
      }}</span>
    </div>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import IconComponent from '@/common/ui/IconComponent';

  export default {
    name: 'ActionItems',
    components: { ComponentInput, IconComponent },
    props: {
      actions: {
        type: Array,
        default: () => [],
      },
      columnName: {
        type: String,
        default: '',
      },
      btnName: {
        type: String,
        default: '',
      },
      isWithLink: {
        type: Boolean,
        default: false,
      },

      headerClass: {
        type: String,
        default: '',
      },
      isDisableBtn: {
        type: Boolean,
        default: false,
      },
      nameMaxLength: {
        type: Number,
        default: 512,
      },
    },
    methods: {
      addAction() {
        this.$emit('add');
      },
      deleteAction(index) {
        this.$emit('delete', index);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../styles/StepCard.scss';
</style>
