<template>
  <div class="form-group">
    <p class="label-field">{{ header }}</p>

    <div v-for="(doc, index) in documents" :key="index" class="card-documents card-list-area">
      <div class="card-list-area__w-70">
        <SelectSingleComponentCheckbox
          v-model="doc.id"
          trackBy="text"
          valueProp="id"
          labelOptions="text"
          :options="options"
          :canClear="false"
          :placeholder="placeholder"
        />
      </div>
      <IconComponent name="delete-not-fill" class="card-delete-icon" @click="deleteItem(index)" />
    </div>

    <div v-if="getDuplicates" class="mb-10">
      <span class="invalid-msg">Пожалуйста, удалите пустые или дублирующиеся элементы</span>
    </div>

    <div>
      <span @click="addItem" :class="['card-add-btn-list', { disabled: getDuplicates }]"
        ><span>+</span> {{ buttonName }}</span
      >
    </div>
  </div>
</template>

<script>
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import IconComponent from '@/common/ui/IconComponent';

  export default {
    name: 'DocumentItem',
    components: { SelectSingleComponentCheckbox, IconComponent },
    props: {
      header: {
        type: String,
        default: '',
      },
      documents: {
        type: Array,
        default: () => [],
      },
      options: {
        type: Array,
        default: () => [],
      },
      buttonName: {
        type: String,
        default: 'Добавить',
      },
      placeholder: {
        type: String,
        default: 'Выберите',
      },
    },
    computed: {
      getDuplicates() {
        const idsDocuments = this.documents.map((i) => i.id);
        const duplicates = idsDocuments.filter((document, index) => {
          return idsDocuments.indexOf(document) !== index;
        });
        return duplicates.length;
      },
    },
    watch: {
      getDuplicates(val) {
        this.$emit('duplicates', val);
      },
    },
    methods: {
      addItem() {
        this.$emit('add');
      },
      deleteItem(index) {
        this.$emit('delete', index);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../styles/StepCard.scss';
</style>
