<template>
  <h3 class="custom-form__title mb-27">Время и стоимость</h3>
  <div class="card-time-and-cost">
    <div class="card-time-and-cost__time-wrapper">
      <div class="card-time-and-cost__time">
        <div class="custom-form__inputBlock">
          <p class="custom-form__label">Дни</p>
          <ComponentInput
            v-model="getDuration"
            type="number"
            mod="rectangular"
            placeholder="Укажите длительность операции в днях"
            min="0"
          />
        </div>
        <div class="form-group form-group-error-absolute">
          <div class="time-switch">
            <div class="one" :class="{ active: isHourFormat }" @click="switchHandTime(true)">Часы</div>
            <div class="two" :class="{ active: !isHourFormat }" @click="switchHandTime(false)">Минуты</div>
          </div>
          <ComponentInput
            v-if="isHourFormat"
            v-model="getHour"
            type="onlyNumber"
            mod="rectangular"
            placeholder="Длительность в часах"
            min="1"
            max="23"
            maxLength="2"
            instantErrors
          />

          <ComponentInput
            v-else
            v-model="getMinute"
            type="onlyNumber"
            mod="rectangular"
            placeholder="Длительность в минутах"
            min="1"
            max="59"
            maxLength="2"
            instantErrors
          />
        </div>
      </div>
      <div class="custom-form__inputBlock">
        <p class="custom-form__label">Комментарий ко времени</p>
        <ComponentInput
          v-model="getDurationComment"
          mod="rectangular"
          type="textarea"
          :textareaStyles="{ 'min-height': '120px' }"
          placeholder="Введите"
        />
      </div>
    </div>

    <div class="card-time-and-cost__cost-wrapper">
      <div class="custom-form__inputBlock">
        <p class="custom-form__label">Стоимость, руб.</p>
        <ComponentInput
          v-model="getCost"
          type="number"
          mod="rectangular"
          placeholder="Укажите стоимость операции в рублях"
          min="0"
        />
      </div>
      <div class="custom-form__inputBlock">
        <p class="custom-form__label">Комментарий к стоимости</p>
        <ComponentInput
          v-model="getCostComment"
          type="textarea"
          mod="rectangular"
          :textareaStyles="{ 'min-height': '120px' }"
          placeholder="Введите"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';

  export default {
    emits: [
      'update:duration',
      'update:hour',
      'update:minute',
      'update:durationComment',
      'update:cost',
      'update:costComment',
      'switchHandlerTime',
    ],
    components: {
      ComponentInput,
    },
    props: {
      duration: {
        type: Number,
        required: true,
      },
      hour: {
        type: Number,
        required: true,
      },
      minute: {
        type: Number,
        required: true,
      },
      durationComment: {
        type: String,
        required: true,
      },
      cost: {
        type: Number,
        required: true,
      },
      costComment: {
        type: String,
        required: true,
      },
      isHourFormat: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      switchHandTime(value) {
        this.$emit('switchHandlerTime', value);
      },
    },
    computed: {
      getDuration: {
        get() {
          return this.duration;
        },
        set(value) {
          this.$emit('update:duration', value);
        },
      },
      getHour: {
        get() {
          return this.hour;
        },
        set(value) {
          this.$emit('update:hour', value);
        },
      },
      getMinute: {
        get() {
          return this.minute;
        },
        set(value) {
          this.$emit('update:minute', value);
        },
      },
      getDurationComment: {
        get() {
          return this.durationComment;
        },
        set(value) {
          this.$emit('update:durationComment', value);
        },
      },
      getCost: {
        get() {
          return this.cost;
        },
        set(value) {
          this.$emit('update:cost', value);
        },
      },
      getCostComment: {
        get() {
          return this.costComment;
        },
        set(value) {
          this.$emit('update:costComment', value);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/custom-form.scss';

  .card-time-and-cost {
    display: flex;
    gap: 20px;
    width: 90%;

    &__time-wrapper {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    &__time {
      display: flex;
      gap: 20px;
      .form-group {
        width: 50%;
      }
    }
    &__cost-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  .time-switch {
    display: flex;
    padding: 2px;
    flex-wrap: nowrap;
    height: 32px;
    .one,
    .two {
      color: $dark-grey-1;
      min-width: 10px;
      border: none;
      font: $fira-16;
      padding: 0 6px;
      cursor: pointer;
    }
    .active {
      color: $blue;
      text-decoration: underline;
    }
  }
  @media (max-width: $mobile) {
    .card-time-and-cost__time {
      flex-wrap: wrap;

      .form-group {
        width: 100% !important;
      }
    }
  }
  @media (max-width: $laptop) {
    .card-time-and-cost {
      width: 100% !important;
      flex-wrap: wrap;
    }
    .card-time-and-cost__time-wrapper,
    .card-time-and-cost__cost-wrapper {
      width: 100% !important;
    }
  }
</style>
